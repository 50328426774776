<template>
  <div
    :class="`lists ${isEdit ? ' lists--edit' : ''}`"
    v-for="item in data"
    :key="item.id"
  >
    <div v-if="isEdit" class="first-div">
      <van-checkbox v-model="item.active" @click="changeClick"></van-checkbox>
    </div>
    <div class="second-div" @click="toDetail(item.video_id)">
      <img :src="item.surface" />
    </div>
    <div class="third-div" @click="toDetail(item.video_id)">
      <div>{{ item.video_name }}</div>
      <div>{{ item.watch_time }}</div>
      <div v-if="process">已观看到 {{ item.play_percentage.toFixed(0) }}%</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VideoHistoryComponent",
  props: {
    process: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: [],
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    toDetail: {
      type: Function,
      default: function () {},
    },
    changeClick: {
      type: Function,
      default: function () {},
    },
  },
};
</script>
<style lang="scss" scoped>
.lists {
  display: flex;
  margin-bottom: 10px;
  .first-div {
    display: flex;
    align-items: center;
    width: 30px;
  }
  .second-div {
    // width: calc(50vw - 25px);
    width: calc(50vw - 10px);
    img {
      // width: calc(50vw - 25px);
      width: calc(50vw - 10px);
      border-radius: 6px;
    }
  }
  .third-div {
    // width: calc(50vw - 35px);
    width: calc(50vw - 20px);
    padding-left: 10px;
    div:nth-child(1) {
      font-size: 0.15rem;
      margin-bottom: 5px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    div:nth-child(2) {
      margin-bottom: 5px;
      font-family: Helvetica;
      color: #666666;
      font-weight: 500;
      font-size: 0.12rem;
    }
    div:nth-child(3) {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      font-size: 0.12rem;
    }
  }
}

.lists--edit {
  .first-div {
    width: 40px;
  }
  .second-div {
    // width: calc(50vw - 10px);
    width: calc(50vw - 25px);
    img {
      // width: calc(50vw - 10px);
      width: calc(50vw - 25px);
    }
  }
  .third-div {
    width: calc(50vw - 35px);
    // width: calc(50vw - 20px);
  }
}
</style>
