<template>
  <div class="bottom-button-history" v-if="isEdit">
    <div class="left-radio">
      <van-checkbox v-model="isAllChecked" @click="allClick(isAllChecked)">
        全选
      </van-checkbox>
    </div>
    <div class="right-button" @click="deleteClick">删除</div>
  </div>
</template>
<script>
export default {
  name: "VideoHistoryDel",
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    isAllChecked: {
      type: Boolean,
      default: false,
    },
    allClick: {
      type: Function,
      default: function () {},
    },
    deleteClick: {
      type: Function,
      default: function () {},
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-button-history {
  height: 50px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-radio {
    margin-left: 12px;
    font-size: 0.12rem;
  }
  .right-button {
    border-radius: 4px;
    border: 1px solid #fa5151;
    margin-right: 20px;
    padding: 5px 20px;
    font-size: 0.12rem;
    color: #fa5151;
  }
}
</style>
