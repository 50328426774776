<template>
  <div class="top-title">
    <div class="titles">{{ title }}</div>
    <div class="right-button">
      <span @click="editClick">编辑</span>
      <span v-if="isEdit" @click="finshClick">完成</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "VideoHistoryTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    editClick: {
      type: Function,
      default: function () {},
    },
    finshClick: {
      type: Function,
      default: function () {},
    },
  },
};
</script>
<style lang="scss" scoped>
.top-title {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  z-index: 1000;
  position: fixed;
  top: 0px;
  width: calc(100% - 24px);
  align-items: center;
  padding: 12px;

  .titles {
    font-size: 0.17rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  .right-button {
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #666666;
    font-size: 0.14rem;
    span:nth-child(1) {
      margin-right: 15px;
    }
  }
}
</style>
