<template>
  <div
    class="empty-container"
    v-show="
      state.videoList[0].children.length == 0 &&
      state.videoList[1].children.length == 0
    "
  >
    <div>
      <v-iconFontSymbol :icon="'iconhistory'" style="font-size: 1.8rem" />
      <div class="text-containers">快去看视频吧</div>
    </div>
  </div>
  <div
    v-show="
      state.videoList[0].children.length > 0 ||
      state.videoList[1].children.length > 0
    "
  >
    <div class="history-container">
      <VideoHistoryTitle
        title="全部视频"
        :isEdit="state.isEdit"
        :editClick="editClick"
        :finshClick="finshClick"
      />

      <div class="history-list-container">
        <div
          class="list-detail"
          v-for="(item, index) in state.videoList"
          :key="index"
        >
          <div class="title" v-if="item.children.length == 0 ? false : true">
            {{ item.name }}
          </div>
          <div :class="state.style ? 'content-list-other' : 'content-list'">
            <VideoHistoryComponent
              :data="item.children"
              :isEdit="state.isEdit"
              :toDetail="toDetail"
              :changeClick="changeClick"
            />
          </div>
        </div>
      </div>
    </div>
    <VideoHistoryDel
      :allClick="allClick"
      :isEdit="state.isEdit"
      :deleteClick="deleteClick"
      :isAllChecked="state.isAllChecked"
    />
  </div>
</template>
<script>
import { reactive, getCurrentInstance, onMounted } from "vue";
import { Toast } from "vant";
import { useRouter } from "vue-router";

import personalApi from "@/api/personal";
import IconFontSymbol from "@/components/IconFontSymbol.vue";
import VideoHistoryDel from "@/components/others/VideoHistoryDel";
import VideoHistoryTitle from "@/components/titles/VideoHistoryTitle";
import VideoHistoryComponent from "@/components/videos/VideoHistoryComponent";

export default {
  name: "History",
  components: {
    VideoHistoryDel,
    VideoHistoryTitle,
    VideoHistoryComponent,
    "v-iconFontSymbol": IconFontSymbol,
  },
  setup() {
    const state = reactive({
      videoList: [
        { name: "今天", children: [] },
        { name: "更早", children: [] },
      ],
      isEdit: false,
      isAllChecked: false,
      style: true,
    });
    const { proxy } = getCurrentInstance();
    const router = useRouter();

    const date = new Date(),
      years = date.getFullYear(),
      months = date.getMonth() + 1,
      days = date.getDate();

    const getListData = async () => {
      const res = await proxy.$request.handleFetchGet(personalApi.his_list);
      let newList = [
        { name: "今天", children: [] },
        { name: "更早", children: [] },
      ];
      if (res.watch_list) {
        res.watch_list.forEach((item) => {
          let year = Number(item.watch_time.split("/")[0]),
            month = Number(item.watch_time.split("/")[1][1]),
            day =
              item.watch_time.split("/")[2].split(" ")[0][0] == "0"
                ? Number(item.watch_time.split("/")[2].split(" ")[0][1])
                : Number(item.watch_time.split("/")[2].split(" ")[0]);
          if (year < years) {
            newList[1].children.push(item);
          } else {
            if (month < months) {
              newList[1].children.push(item);
            } else {
              if (day < days) {
                newList[1].children.push(item);
              } else {
                newList[0].children.push(item);
              }
            }
          }
        });
      }
      newList.forEach((item) => {
        if (item.children.length !== 0) {
          item.children.forEach((data) => {
            data.active = false;
          });
        }
      });
      newList.forEach((item) => {
        if (item.children.length > 0) {
          item.children.forEach((data) => {
            let progress = Number(
              data.play_progress == "undefined" ? 0 : data.play_progress
            );
            let total = 0;
            let h = Number(data.video_time.split(":")[0]),
              m = Number(data.video_time.split(":")[1]),
              s = Number(data.video_time.split(":")[2]);
            total = h * 3600 + m * 60 + s;
            data["play_percentage"] =
              Number((progress / total).toFixed(2)) * 100;
          });
        }
      });
      state.videoList = newList;
      Toast.clear();
    };

    onMounted(() => {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中...",
      });
      getListData();
    });

    const editClick = () => {
      state.isEdit = true;
      state.style = false;
    };

    const finshClick = () => {
      state.isEdit = false;
      state.style = true;
    };

    const changeClick = () => {
      let isCheckedArr = [];
      state.videoList.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach((data) => {
            isCheckedArr.push(data.active);
          });
        }
      });
      if (isCheckedArr.indexOf(false) == -1) {
        state.isAllChecked = true;
      } else {
        state.isAllChecked = false;
      }
    };

    const allClick = (value) => {
      state.videoList.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach((data) => {
            data.active = value;
          });
        }
      });
    };

    const toDetail = (video_id) => {
      router.push({
        path: "/hotDetail",
        name: "hotDetail",
        query: {
          isHot: true,
          video_id: video_id,
        },
      });
    };

    const deleteData = async (id_list) => {
      await proxy.$request.handleFetchPostJson(personalApi.del_his, {
        id_list: id_list,
      });
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中...",
      });
      getListData();
    };

    const deleteClick = () => {
      let id_list = [];
      state.videoList.forEach((item) => {
        if (item.children.length !== 0) {
          item.children.forEach((data) => {
            if (data.active) {
              id_list.push(data.id);
            }
          });
        }
      });
      if (id_list.length == 0) {
        Toast("请选择要删除的视频");
      } else {
        deleteData(id_list);
      }
    };

    return {
      state,
      editClick,
      finshClick,
      changeClick,
      allClick,
      toDetail,
      deleteClick,
    };
  },
};
</script>
